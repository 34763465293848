import { formalizedTag } from '@shared/Presentation/helpers/formalizedTag'

import Button from '@shared/Presentation/components/common/Button'
import Container from '@shared/Presentation/components/common/Container'
import Modal from '@shared/Presentation/components/common/modal/Modal'
import Text from '@shared/Presentation/components/common/Text'

import { editionsTags } from '@shared/constants/tags'

type TPreviewModal = {
  formFields: any,
  isShowing: boolean
  tags: string[]
  getValues: () => void
  toggle: () => void
}

const PreviewModal: React.FC<TPreviewModal> = ({ formFields, isShowing, tags, getValues, toggle }) => {
  const formValues: any = getValues()

  return (
    <Modal open={isShowing}>
      <Text className='border-b-2 border-gray-400 font-semibold mb-0 p-4 text-3xl'>Previsualización de la notificación</Text>
      <section>
        {
          Object.entries(formValues).map(([ key, value ]: [string, string]) => {
            return (
              <Container className='flex gap-1 m-0 mt-3 px-3 text-xl' key={key}>
                <Text className='font-semibold'>{formFields[key]}:</Text>
                {
                  key === 'image'
                    ? <a href={value} target='_blank' rel='noreferrer'>{value}</a>
                    : <Text>{value}</Text>
                }
              </Container>
            )
          })
        }
        <Container className='flex flex-col gap-1 mt-3'>
          <Text className='border-b-2 border-gray-400 font-semibold mb-0 p-4 text-3xl'>Tags</Text>
          {
            tags.length === 0
              ? <Text className='font-semibold flex justify-center text-xl mt-2'>No se ha asociado ninguna edición a la notificación.</Text>
              : <table className='mt-3 p-2'>
                <thead className='border border-black'>
                  <tr className='font-semibold text-xl'>
                    <th className='border border-black p-2'>Nombre</th>
                    <th className='border border-black p-2'>Valor Urban</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tags.map(tag => {
                      return (
                        <tr key={tag}>
                          <td className='border border-black p-2'>
                            {
                              !editionsTags[tag] 
                                ? <Text>{tag}</Text>
                                : <Text>{editionsTags[tag]}</Text>
                            }
                          </td>
                          <td className='border border-black p-2'>
                            {
                              !editionsTags[tag]
                                ? formalizedTag(tag)
                                : Object.keys(editionsTags).find((key) => key === tag)
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
          }
        </Container>
      </section>
      <Container className='flex font-semibold justify-end p-2 text-white'>
        <Button className='bg-blue-500' onClick={toggle}>Volver</Button>
      </Container>
    </Modal>
  )
}

export default PreviewModal