import { create } from 'zustand'

import NotificationState from '@modules/Notification/Presentation/store/NotificationState'
import notificationRepository from '@modules/Notification/Infrastructure/Repository/NotificationRepository'

import { TQueryFilters } from '@modules/Notification/types'

export const useNotificationStore = create<NotificationState>((set) => {
  return {
    isLoading: false,
    notifications: [],
    filter: {
      filters: {},
      page: 1,
      limit: 10,
      sort: 'createdAt|desc'
    },
    totalPages: 10,
    getNotifications: async () => {
      const state = useNotificationStore.getState()
      const filters = state.filter

      set({ isLoading: true })
      const { notifications, paginationInfo } = await notificationRepository.getNotifications(filters)
      set({ 
        isLoading: false, 
        notifications,
        totalPages: Math.ceil(paginationInfo.totalInCollection / paginationInfo.rowsPerPage)
      })
    },
    setFilters: (filters: TQueryFilters | any) => {
      set({ filter: filters })
    }
  }
})
