import { API } from '../Axios'

type TPhotoCenterThumborData = {
  businessUnit: string
  id: string
  commands: TPhotoCenterThumborCommands
}

type TPhotoCenterThumborCommands = {
  resize?: {
    height: number
    width: number
  }
}

export const resizePhotoCenterThumbor = async (data: TPhotoCenterThumborData) => {
  const token = sessionStorage.getItem('token')

  const res = await API.post('/api/v1/photo-center/transform', data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  const { data: response } = res
  return response
}