import { SemanticCOLORS, Table } from 'semantic-ui-react'

import ResendNotificationButton from '@modules/Notification/Presentation/components/ResendNotificationButton'
import TableFooter from '@shared/Presentation/components/common/table/TableFooter'
import TableHeader from '@shared/Presentation/components/common/table/TableHeader'
import SendIcon from '@shared/Presentation/icons/SendIcon'

type TCustomTable = {
  color: SemanticCOLORS
  columns: TColumn[]
  data: any
  isLoading: boolean
  pagination: TPagination
  totalPages: number
}

type TColumn = {
  name: string
  title: string
  filter?: boolean
  props?: any
}

type TPagination = {
  page: number
  limit: number
}

const CustomTable: React.FC<TCustomTable> = ({ color, columns, data, isLoading, pagination, totalPages }) => {
  const totalColumns = columns.length + 1

  const getValue = (item, col) => {
    const value = item[col.name]

    if (typeof col.format === 'function') {
      return col.format(value, col, item)
    }

    return value
  }

  return (
    <Table celled color={color} selectable sortable striped>
      <TableHeader columns={columns} />
      <Table.Body>
        {
          data?.map((item) => (
            <Table.Row key={item.id}>
              {
                columns.map((col) => {
                  return (
                    <Table.Cell key={`${col.name}-${item[item.id]}`} {...col.props}>
                      {
                        col.name === 'state'
                          ? <div className={`${item.state === 'Error al enviar' ? 'bg-red-500' : item.state === 'Pendiente' ? 'bg-yellow-500' : 'bg-green-500'} 
                            flex justify-center px-1 py-2 rounded-full`} title={item.state}
                          >
                            <SendIcon />
                          </div>
                          : <div className='min-w-[60px]'>{getValue(item, col)}</div>
                      }
                    </Table.Cell>
                  )
                })
              }
              <Table.Cell textAlign='center' width={2}>
                <ResendNotificationButton id={item.id} />
              </Table.Cell>
            </Table.Row>
          ))
        }
        {
          !isLoading && (!data || data.length === 0) 
          && <Table.Row>
            <Table.Cell colSpan={totalColumns} textAlign='center' verticalAlign='middle' warning>
              No hay resultados
            </Table.Cell>
          </Table.Row>
        }
      </Table.Body>
      <TableFooter
        pagination={pagination}
        totalColumns={totalColumns}
        totalPages={totalPages}
      />
    </Table>
  )
}

export default CustomTable