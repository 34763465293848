import './index.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css'

import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from '@config/azure'

import ReactDOM from 'react-dom/client'

import App from './App'
import Interceptor from '@shared/Infrastructure/Services/Axios'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Interceptor>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Interceptor>

)