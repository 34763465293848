import { create } from 'zustand'

import User from '@modules/User/Domain/User'
import UserState from '@modules/User/Presentation/store/UserState'

export const useUserStore = create<UserState>((set) => {
  return {
    user: null,
    setUser: (user: User) => set({ user })
  }
})