type TContainer = {
  children: React.ReactNode
  className?: string
}

const Container: React.FC<TContainer> = ({ children, className }) => {
  return (
    <div className={className}>
      { children }
    </div>
  )
}

export default Container