import { Control, Controller, FieldValues } from 'react-hook-form'
import { Input } from 'semantic-ui-react'

import Container from '@shared/Presentation/components/common/Container'
import Text from '@shared/Presentation/components/common/Text'

type TFormInput = {
  className?: string
  control: Control<FieldValues>
  label?: string
  maxLength?: number
  name: string
  placeholder?: string
  withLabel?: boolean
}

const FormInput: React.FC<TFormInput> = ({ className, control, label, maxLength, name, placeholder, withLabel = true }) => {
  return (
    <Container className='flex items-center p-3 w-full'>
      { withLabel && <Text className='m-0 w-1/5'>{label}</Text> }
      <Controller 
        control={control}
        name={name}
        render={({ field }) => <Input 
          className={`${className} w-4/5`}
          id={name}
          maxLength={maxLength}
          placeholder={placeholder}
          {...field}
        />}
      />
    </Container>
  )
}

export default FormInput