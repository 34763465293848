import Container from '@shared/Presentation/components/common/Container'
import Header from '@shared/Presentation/components/layout/Header'

type TLayout = {
  children: React.ReactNode
}

const Layout: React.FC<TLayout> = ({ children }) => {
  return (
    <>
      <Header />
      <Container className='p-5'>
        { children }
      </Container>
    </>
  )
}

export default Layout