import Edition from '@modules/Edition/Domain/Edition'

import Container from '@shared/Presentation/components/common/Container'
import EditionCardWithCheckbox from '@shared/Presentation/components/common/card/edition/EditionCardWithCheckbox'
import EditionCardWithMultipleSelect from './EditionCardWithMultipleSelect'
import Text from '@shared/Presentation/components/common/Text'

type TEditionCard = {
  edition: Edition
}

const EditionCard: React.FC<TEditionCard> = ({ edition }) => {
  const now = new Date(Date.now())

  return (
    <Container className='bg-[#dadada] p-4 w-full'>
      {
        edition.tags.length === 1
          ? <EditionCardWithCheckbox edition={edition} />
          : <EditionCardWithMultipleSelect edition={edition} />
      }
      <Container className='bg-gray-100 flex gap-1 items-center justify-center p-2'>
        <Text className='font-bold'>{edition.city}:</Text>
        <Text>{ now.toLocaleTimeString(edition.locale, { timeZone: edition.timezone }) }</Text>
      </Container>
    </Container>

  )
}

export default EditionCard