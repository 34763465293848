import { API } from '@shared/Infrastructure/Services/Axios'

import toaster from '@shared/Infrastructure/Toaster/Toaster'

export const resendNotification = async (id: string) => {
  try {
    await API.patch(`/api/v1/notification/${id}/resend`)
  } catch (error) {
    toaster.error('No se ha podido reenviar la notificación')
  }
}
