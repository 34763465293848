import { toast } from 'react-toastify'

const toaster = {
  error: (message: string): void => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT
    })
  },

  info: (message: string): void => {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT
    })
  },

  warning: (message: string): void => {
    toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT
    })
  }
}

export default toaster