import NotificationRepository from '@modules/Notification/Domain/NotificationRepository'
import NotificationRequest from '@modules/Notification/Application/Request/NotificationRequest'

import { listNotifications } from '@modules/Notification/Application/Lister/NotificationLister'
import { sendNotification } from '@modules/Notification/Application/Sender/SendNotification'
import { resendNotification } from '@modules/Notification/Application/Resender/NotificationResender'

import { toNotificationDto } from '@modules/Notification/Infrastructure/NotificationDto'

import { TQueryFilters } from '@modules/Notification/types'

const notificationRepository: NotificationRepository = {
  getNotifications: async (filters: TQueryFilters) => {
    const response = await listNotifications(filters)

    if (!response || !response.items.length) {
      return {
        notifications: [],
        paginationInfo: {
          page: response.page,
          rowsPerPage: response.rowsPerPage, 
          totalInCollection: response.totalInCollection 
        }
      }
    }

    const paginationInfo = { 
      page: response.page, 
      rowsPerPage: response.rowsPerPage, 
      totalInCollection: response.totalInCollection 
    }
    
    const notificationsMapped = toNotificationDto(response.items)
    return {
      notifications: notificationsMapped,
      paginationInfo 
    }
  },

  send: async (notification: NotificationRequest) => {
    await sendNotification(notification)
  },

  resend: async (notificationId: string) => {
    await resendNotification(notificationId)
  }
}

export default notificationRepository