import Container from '@shared/Presentation/components/common/Container'
import Text from '@shared/Presentation/components/common/Text'
import UnauthorizedIcon from '@shared/Presentation/icons/UnauthorizedIcon'

const UnauthorizedPage = () => {
  return (
    <Container className='flex flex-col items-center justify-center m-auto'>
      <UnauthorizedIcon />
      <Text className='font-semibold text-4xl'>No tienes permisos para acceder al editor. Por favor, contacte al administrador.</Text>
    </Container>
  )
}

export default UnauthorizedPage