const UnauthorizedIcon = () => {
  return (
    <svg 
      fill="none"
      height="320" 
      strokeWidth="2" 
      stroke="currentColor" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/4800/svg"
      width="320" 
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm.01 13l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" fill="currentColor"></path>
    </svg>
  )
}

export default UnauthorizedIcon
