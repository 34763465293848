export const panels = [
  {
    id: 'diarioas',
    name: 'Diario AS',
    index: 0
  },
  {
    id: 'elpais',
    name: 'El País',
    index: 1
  },
  {
    id: 'elpais-america',
    name: 'El Pais',
    index: 1
  }
]