import { API } from '@shared/Infrastructure/Services/Axios'

import toaster from '@shared/Infrastructure/Toaster/Toaster'

export const listEditions = async (businessUnit: string) => {
  try {
    const response = await API.get(`/api/v1/edition?filters[businessUnit]=${businessUnit}`)
    return response
  } catch (error) {
    toaster.error('No se han podido obtener las ediciones.')
  }
}
