import { Router as WouterRouter, Route, Switch } from 'wouter'

import Home from '@pages/Home'
import NotFound from '@pages/NotFound'
import UserNotifications from '@pages/UserNotifications'
import UnauthorizedPage from '@pages/Unauthorized'

const Router = () => {
  return (
    <WouterRouter>
      <Switch>
        <Route path='/' component={Home} />
        <Route path='/notifications/list' component={UserNotifications} />
        <Route path='/unauthorized' component={UnauthorizedPage} />
        <Route component={NotFound} />
      </Switch>
    </WouterRouter>
  )
}

export default Router