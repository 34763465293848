import Notification from '@modules/Notification/Domain/Notification'
import { NotificationApi } from '@modules/Notification/Application/Response/NotificationApiResponse'

export const toNotificationDto = (notifications: NotificationApi[]): Notification[] => {
  const businessUnitsMap = {
    as: 'Diario AS',
    ep: 'El País'
  }

  const notificationStates = {
    error: 'Error al enviar',
    pending: 'Pendiente',
    retry: 'Error al enviar',
    sent: 'Enviada'
  }

  const notificationsMapped: Notification[] = notifications.map(notification => ({
    id: notification.id,
    businessUnit: businessUnitsMap[notification.businessUnit],
    message: notification.message,
    tags: notification.tags,
    url: notification.url,
    state: {
      name: notificationStates[notification.state.name],
      message: notification.state.message
    },
    createdBy: notification.createdBy,
    createdAt: notification.createdAt
  }))

  return notificationsMapped
}