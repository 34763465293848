import { useEffect, useMemo, useRef } from 'react'

export const useDebounce = (callback, wait) => {
  const ref = useRef(null)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  return useMemo(() => {
    let timeoutId = null
    const func = () => {
      ref.current?.()
    }

    return () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func()
      }, wait)
    }
  }, [])
}
