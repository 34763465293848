import { useMemo } from 'react'
import dayjs from 'dayjs'

import { useNotificationStore } from '@modules/Notification/Presentation/store/NotificationStore'

import { editionsTags } from '@shared/constants/tags'

const useNotificationTableInfo = () => {
  const { notifications } = useNotificationStore()

  const columns = useMemo(() => [
    {
      name: 'businessUnit',
      title: 'Medio',
      filter: false
    },
    {
      name: 'message',
      title: 'Mensaje'
    },
    {
      name: 'url',
      title: 'URL',
      filter: false
    },
    {
      name: 'tags',
      title: 'Ediciones',
      filter: false
    },
    {
      name: 'state',
      title: 'Estado',
      filter: false
    },
    {
      name: 'createdAt',
      title: 'Fecha de creación',
      filter: false
    }
  ], [])

  const data = useMemo(() => [
    notifications.map((notification) => {
      const tagsMapped = notification.tags.map(tag => editionsTags[tag])

      return {
        id: notification.id,
        businessUnit: notification.businessUnit,
        message: notification.message,
        url: notification.url,
        tags: tagsMapped,
        state: notification.state.name,
        createdAt: dayjs(notification.createdAt).format('DD-MM-YYYY HH:mm:ss')
      }
    })
  ], [ notifications ])

  return { 
    columns,
    data: data[0]
  }
}

export default useNotificationTableInfo