import { Image } from 'semantic-ui-react'

type TImage = {
  src: string
  size: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive'
}

const Picture: React.FC<TImage> = ({ src, size }) => {
  return (
    <Image 
      src={src}
      size={size}
    />
  )
}

export default Picture