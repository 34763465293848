import { useState } from 'react'
import { Checkbox, Form, Modal as SemanticModal } from 'semantic-ui-react'
import { FieldValues, UseFormSetValue } from 'react-hook-form'

import toaster from '@shared/Infrastructure/Toaster/Toaster'

import { resizePhotoCenterThumbor } from '@shared/Infrastructure/Services/PhotoCenterThumbor/PhotoCenterThumborResize'

import Button from '@shared/Presentation/components/common/Button'
import Modal from '@shared/Presentation/components/common/modal/Modal'
import Text from '@shared/Presentation/components/common/Text'
import { number, object } from 'yup'
import { useTagStore } from '@modules/Tag/Presentation/store/TagStore'

type TResizerModal = {
  businessUnit: string
  open: boolean
  setValue: UseFormSetValue<FieldValues>
  closeResizerModal: () => void
}

type TImageSize = {
  height: number
  width: number
  type: string
}

const ResizerModal: React.FC<TResizerModal> = ({ businessUnit, open, setValue, closeResizerModal }) => {
  const validationSchema = object().shape({
    width: number()
      .min(300, 'El ancho mínimo debe ser de 300px')
      .max(1038, 'El ancho máximo debe ser de 1038px'),
    height: number()
      .min(300, 'El alto mínimo debe ser de 300px')
      .max(1038, 'El alto máximo debe ser de 1038px')
  })

  const DEFAULT_IMAGE_SIZE = {
    height: 1038,
    width: 1038,
    type: 'square'
  }

  const [errors, setErrors] = useState(null)
  const [imageSize, setImageSize] = useState<TImageSize>(DEFAULT_IMAGE_SIZE)
  const { imageAuth, imageExt, imageId, setImage } = useTagStore()

  const handleChange = (_, data) => {
    const { value } = data
    let imageSize = null

    if (value === 'square') {
      imageSize = { height: 1038, width: 1038, type: value }
    } else if (value === 'horizontalRectangle') {
      imageSize = { height: 360, width: 720, type: value }
    } else {
      imageSize = { height: 1036, width: 518, type: value }
    }

    setImageSize(imageSize)
  }

  const handleResize = async () => {
    validationSchema.validate(imageSize, { abortEarly: false })
      .then(async () => {
        const data = {
          businessUnit,
          auth: imageAuth,
          id: `${imageId}.${imageExt}`,
          commands: {
            resize: {
              height: imageSize.height,
              width: imageSize.width
            }
          }
        }
    
        const response = await resizePhotoCenterThumbor(data)
        if (response?.url?.length) {
          const { url } = response
          setValue('image', url)
          setImage(url)
          closeResizerModal()
        }
      })
      .catch(error => {
        const newErrors = {}
        error?.inner?.forEach(err => {
          newErrors[err.path] = err.message
        })
        setErrors(newErrors)
      })
  }

  return (
    <Modal open={open}>
      <SemanticModal.Header>Photo Center Resize</SemanticModal.Header>
      <SemanticModal.Content>
        <Text className='mb-5 text-3xl'>
          Establece el tamaño de la imagen de PhotoCenter
        </Text>
        <Text className='mb-5 text-yellow-600 text-xl'>
          Recuerda: El ancho mínimo de la imagen es de 300px y el máximo de 1038px.
        </Text>
        <Form>
          <Form.Group widths='equal'>
            <Form.Input
              disabled={imageSize.type !== 'customSize'}
              error={errors?.width ? { content: errors.width } : null}
              label={'Ancho'}
              min={300}
              max={1038}
              name='width'
              placeholder={'Ancho'}
              required
              type='number'
              value={imageSize.width}
              onChange={(e, data) => setImageSize({ ...imageSize, width: Number(data.value) })}
            />
            <Form.Input
              disabled={imageSize.type !== 'customSize'}
              error={errors?.height ? { content: errors.height } : null}
              label={'Alto'}
              min={300}
              max={1038}
              name='height'
              placeholder={'Alto'}
              required
              type='number'
              value={imageSize.height}
              onChange={(e, data) => setImageSize({ ...imageSize, height: Number(data.value) })} 
            />
          </Form.Group>
          <div className='flex flex-col gap-3'>
            <Text className='text-base'>* Tamaños recomendados por Urban Airship</Text>
            <Checkbox
              checked={imageSize.type === 'horizontalRectangle'}
              label='720 x 360px'
              value='horizontalRectangle'
              onChange={handleChange}
            />
            <Checkbox
              checked={imageSize.type === 'square'}
              label='1038 x 1038px'
              value='square'
              onChange={handleChange}
            />
            <Checkbox
              checked={imageSize.type === 'verticalRectangle'}
              label='518 x 1036px'
              value='verticalRectangle'
              onChange={handleChange}
            />
            <Text>* Usar tamaño personalizado</Text>
            <Checkbox 
              checked={imageSize.type === 'customSize'}
              label='Personalizar tamaño'
              value='customSize'
              onChange={() => setImageSize({ 
                ...imageSize, 
                type: 'customSize' 
              })}
            />
          </div>
        </Form>
      </SemanticModal.Content>
      <SemanticModal.Actions>
        <Button
          className='bg-[#3A4856] mr-1 text-white'
          onClick={closeResizerModal}
        >
          Cancelar
        </Button>
        <Button
          className='bg-green-600 text-white'
          onClick={handleResize}
        >
          Redimensionar
        </Button>
      </SemanticModal.Actions>
    </Modal>
  )
}

export default ResizerModal