import { useLocation } from 'wouter'

import Button from '@shared/Presentation/components/common/Button'
import Container from '@shared/Presentation/components/common/Container'
import NotFoundIcon from '@shared/Presentation/icons/NotFoundIcon'
import Text from '@shared/Presentation/components/common/Text'

const NotFound = () => {
  const [, setLocation] = useLocation()

  return (
    <Container className='flex flex-col items-center justify-center m-auto'>
      <NotFoundIcon />
      <Text className='font-semibold text-5xl'>Página no encontrada</Text>
      <Button className='bg-zinc-600 font-semibold mt-8 p-6 text-white' onClick={() => setLocation('/')}>Volver al Inicio</Button>
    </Container>
  )
}

export default NotFound