import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useEditionStore } from '@modules/Edition/Presentation/store/EditionStore'
import { useTagStore } from '@modules/Tag/Presentation/store/TagStore'

import useModal from '@shared/Presentation/hooks/useModal'
import useShowAlert from '@shared/Presentation/hooks/useShowAlert'

import Button from '@shared/Presentation/components/common/Button'
import CharacterCounter from '@shared/Presentation/components/common/CharacterCounter'
import Container from '@shared/Presentation/components/common/Container'
import DeleteAllTagsButton from '@shared/Presentation/components/common/DeleteAllTagsButton'
import EditionCard from '@shared/Presentation/components/common/card/edition/EditionCard'
import ErrorMessage from '@shared/Presentation/components/common/ErrorMessage'
import FormInput from '@shared/Presentation/components/common/Input'
import IngestTagsButton from '@shared/Presentation/components/common/IngestTagsButton'
import PreviewModal from '@shared/Presentation/components/common/modal/preview/PreviewModal'
import TagSeeker from '@shared/Presentation/components/common/TagSeeker'
import Text from '@shared/Presentation/components/common/Text'

import NotificationData from '@shared/Presentation/editors/diarioas/types/NotificationData'
import NotificationRequest from '@modules/Notification/Application/Request/NotificationRequest'

import notificationRepository from '@modules/Notification/Infrastructure/Repository/NotificationRepository'

import { asFormFields } from '@shared/constants/formFields'
import { editionsTags } from '@shared/constants/tags'
import ImageSelectorModal from '@shared/Presentation/components/common/modal/imageSelector/ImageSelectorModal'
import NotificationExtraInfo from './types/ NotificationExtraInfo'

const AsForm = () => {
  const { control, formState: { errors, isSubmitSuccessful }, getValues, handleSubmit, reset, setValue, watch } = useFormContext()

  const image = watch('image')
  const message = watch('message')
  const newsUrl = watch('url')

  const { editions, getEditions } = useEditionStore()
  const {
    openImageSelectorModal,
    toggleImageSelectorModal,
    openPreviewModal,
    togglePreviewModal
  } = useModal()
  const { showAlert } = useShowAlert()
  const { tags, resetImage, resetTags } = useTagStore()

  useEffect(() => {
    getEditions('as')
  }, [])

  useEffect(() => {
    resetTags()
  }, [newsUrl])

  const onSubmit = (data: NotificationData) => {
    const tagFound = Object.keys(editionsTags).find(keyTag => tags.includes(keyTag))

    if (tagFound) {
      showAlert(
        'info', 
        'Se va a enviar la notificación', 
        '¿Está seguro de que desea enviar la notificación?', 
        true, 
        true, 
        () => sendNotification(data)
      )
    }
  }

  const sendNotification = (data) => {
    const notification = buildNotification(data, tags)
    notificationRepository.send(notification)
    reset()
    resetTags()
  }

  const onDeleteImage = () => {
    setValue('image', '')
    resetImage()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container className='bg-[#dadad5] border-b-2 border-solid border-black p-2'>
        <Text className='font-semibold'>Notificación</Text>
      </Container>
      <Container className='flex'>
        <FormInput
          control={control}
          label='Enlace de la notificación'
          name='url'
          placeholder='https://as.com/futbol/seleccion/el-caso-pedri-y-el-riesgo-de-doblar-eurocopa-y-juegos-olimpicos-n/#tooltip1'
        />
      </Container>
      { <ErrorMessage message={errors?.url?.message} /> }
      <Container className='flex'>
        <FormInput
          control={control}
          label='Título de la notificación'
          name='title'
          placeholder='Última Hora'
        />
      </Container>
      <Container className='flex'>
        <FormInput
          control={control}
          label='Mensaje de la notificación'
          maxLength={114}
          name='message'
          placeholder='El ‘Caso Pedri’ y el riesgo de doblar Eurocopa y Juegos Olímpicos'
        />
      </Container>
      <CharacterCounter 
        characters={message.length}
        maxCharacters={114}
      />
      { <ErrorMessage message={errors?.message?.message} /> }
      <Container className='flex items-center p-3 w-full'>
        <Text className='m-0 w-1/5'>Imagen</Text>
        {
          image.length > 0
            ? <div className='flex gap-2 items-center'>
              <Button
                className='bg-slate-500 font-semibold text-white'
                type='button'
                onClick={() => {}}
              >
                <a
                  className='text-white hover:text-white'
                  href={image} 
                  rel='noreferrer noopener'
                  target='_blank'>Previsualizar imagen
                </a>
              </Button>
              <Button
                className='bg-slate-500 font-semibold text-white'
                type='button'
                onClick={onDeleteImage}
              >
                Borrar Imagen
              </Button>
            </div>
            : <Button
              className='bg-slate-500 font-semibold text-white'
              type='button'  
              onClick={toggleImageSelectorModal}
            >
              Seleccionar Imagen
            </Button>
        }
        <ImageSelectorModal
          businessUnit='diarioas'
          isShowing={openImageSelectorModal}
          newsUrl={newsUrl}
          setValue={setValue}
          toggleImageSelectorModal={toggleImageSelectorModal}
        />
      </Container>
      <Container className='flex font-semibold gap-2 justify-end p-3 text-white'>
        <Button className='bg-blue-500' onClick={togglePreviewModal} type='button'>Previsualizar</Button>
        <Button className='bg-green-600' onClick={handleSubmit(data => onSubmit(data))}>Envíar</Button>
      </Container>
      <Container className='bg-[#dadad5] border-b-2 border-solid border-black p-2'>
        <Text className='font-semibold'>Aplicación</Text>
      </Container>
      { 
        !Object.keys(editionsTags).find(keyTag => tags.includes(keyTag)) && isSubmitSuccessful
          && <ErrorMessage message='* Debe seleccionar una edición para poder enviar la notificación' /> 
      }
      <Container className='grid grid-cols-4 gap-5 mt-2'>
        {
          editions.length
            ? editions.map(edition => <EditionCard edition={edition} key={edition.id} />) : null
        }
      </Container>
      {
        openPreviewModal && <PreviewModal
          formFields={asFormFields}
          isShowing={openPreviewModal}
          getValues={getValues}
          tags={tags}
          toggle={togglePreviewModal}
        />
      }
    </form>
  )
}

const buildNotification = (notification: NotificationData, tags: string[]): NotificationRequest => {
  const DEFAULT_ALERT_TYPE = 'EDITORIAL_INFORMATIVA_TAGS'

  let extraInfo: NotificationExtraInfo = {
    site: 'as.com'
  }

  if (notification.image.length > 0) {
    extraInfo = {
      ...extraInfo,
      photo_url: notification.image
    }
  }

  if (notification.title.length) {
    extraInfo = {
      ...extraInfo,
      title: notification.title
    }
  }

  const formattedTags = tags.map(tag => {
    if (editionsTags[tag]) return tag
    return `AS_INF_${tag.replaceAll(' ', '_').toUpperCase()}_A_N10`
  })

  return {
    businessUnit: 'as',
    applications: process.env.REACT_APP_APPLICATION,
    providers: process.env.REACT_APP_DEFAULT_PROVIDER,
    alertTypes: DEFAULT_ALERT_TYPE,
    tags: formattedTags,
    message: notification.message,
    url: notification.url,
    extra: extraInfo
  }
}

export default AsForm