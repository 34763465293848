import { API } from '@shared/Infrastructure/Services/Axios'

import toaster from '@shared/Infrastructure/Toaster/Toaster'

import NotificationRequest from '@modules/Notification/Application/Request/NotificationRequest'

export const sendNotification = async (notification: NotificationRequest) => {
  try {
    await API.post('/api/v1/notification', notification)
    toaster.info('La notificación ha sido creada')
  } catch (error) {
    toaster.error(`Error: ${error.code} La notificación no ha podido ser creada`)
  }
}
