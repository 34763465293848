const LogoutIcon = () => {
  return (
    <svg 
      fill="none"
      height="36" 
      stroke="currentColor" 
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke="none" d="M0 0h24v24H0z"/>
      <path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2"/>
      <path d="M9 12h12l-3-3M18 15l3-3"/>
    </svg>
  )
}

export default LogoutIcon