import Container from '@shared/Presentation/components/common/Container'
import Text from '@shared/Presentation/components/common/Text'

type TCharacterCounter = {
  className?: string
  characters: string
  maxCharacters: number
  name?: string
}

const CharacterCounter: React.FC<TCharacterCounter> = ({ characters, maxCharacters }) => {
  return (
    <Container className='flex justify-end mr-5'>
      <Text>{characters} / {maxCharacters} caracteres</Text>
    </Container>
  )
}

export default CharacterCounter