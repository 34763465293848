import * as yup from 'yup'

export const AsFormValidators = yup.object().shape({
  url: yup
    .string()
    .required('* La URL es un campo requerido')
    .matches(/((https):\/\/)([a-z]+\.)?as.com/, '* La URL no cumple el patrón especificado'),
  message: yup
    .string()
    .required('* El mensaje es un campo requerido')
    .max(114, 'El mensaje de la notificación no puede contener más de 114 caracteres')
})