import { useEffect, useState } from 'react'

import { useTagStore } from '@modules/Tag/Presentation/store/TagStore'

import Edition from '@modules/Edition/Domain/Edition'

import Container from '@shared/Presentation/components/common/Container'
import EditionCheckbox from '@shared/Presentation/components/common/card/edition/EditionCheckbox'
import Picture from '@shared/Presentation/components/common/Picture'
import Text from '@shared/Presentation/components/common/Text'

type TEditionCardWithCheckbox = {
  edition: Edition
}

const EditionCardWithCheckbox: React.FC<TEditionCardWithCheckbox> = ({ edition }) => {
  const [checked, setChecked] = useState<boolean>(false)
  const { tags, setTag, removeTag } = useTagStore()

  useEffect(() => {
    if (!tags.length) {
      setChecked(false)
    }
  }, [tags])

  const handleClick = (data) => {
    const { checked } = data
    if (checked) {
      edition.tags.map(tag => setTag(tag.name))
      setChecked(checked)
    } else {
      edition.tags.map(tag => removeTag(tag.name))
      setChecked(checked)
    }
  }

  return (
    <Container className='flex items-center justify-between p-5'>
      <Picture src={`${process.env.PUBLIC_URL}/editions/diarioas.webp`} size='mini' />
      <Text>{edition.prettyName}</Text>
      <EditionCheckbox 
        checked={checked}
        onClick={(e, data) => handleClick(data)}
      />
    </Container>
  )
}

export default EditionCardWithCheckbox