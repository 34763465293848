import Edition from '@modules/Edition/Domain/Edition'
import EditionRepository from '@modules/Edition/Domain/EditionRepository'
import { listEditions } from '@modules/Edition/Application/Lister/ListEditions'
import { toEditionDto } from '@modules/Edition/Infrastructure/EditionDto'

const editionRepository: EditionRepository = {
  getEditions: async (businessUnit: string): Promise<Edition[]> => {
    const response = await listEditions(businessUnit)
    if (!response) return []

    const { data } = response
    const { items: editionsResponse } = data

    const editions = toEditionDto(editionsResponse)
    return editions
  }
}

export default editionRepository