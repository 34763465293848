import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import AsForm from '@shared/Presentation/editors/diarioas/AsForm'
import { AsFormValidators } from '@shared/Presentation/editors/diarioas/AsFormValidators'

const initialValues = {
  image: '',
  message: '',
  title: '',
  url: ''
}

const AsFormProvider = () => {
  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: yupResolver(AsFormValidators),
    reValidateMode: 'onSubmit'
  })

  return (
    <FormProvider {...methods}>
      <AsForm />
    </FormProvider>
  )
}

export default AsFormProvider