import { useEffect } from 'react'

import { useNotificationStore } from '@modules/Notification/Presentation/store/NotificationStore'
import { useUserStore } from '@modules/User/Presentation/store/UserStore'

import Container from '@shared/Presentation/components/common/Container'
import NotificationIcon from '@shared/Presentation/icons/NotificationIcon'
import NotificationTable from '@modules/Notification/Presentation/components/NotificationTable'
import Text from '@shared/Presentation/components/common/Text'

const UserNotifications = () => {
  const { filter, getNotifications, setFilters } = useNotificationStore()
  const { user } = useUserStore()

  useEffect(() => {
    if (user !== null) {
      setFilters({
        ...filter,
        filters: {
          ...filter.filters,
          createdBy: user?.email
        }
      })
  
      getNotifications()
    }
  }, [ user ])

  return (
    <Container>
      <Container className='flex items-center'>
        <NotificationIcon />
        <Text className='flex font-semibold justify-center p-3 text-3xl'>Mis notificaciones</Text>
      </Container>
      <NotificationTable />
    </Container>
  )
}

export default UserNotifications