import { API } from '@shared/Infrastructure/Services/Axios'

import TagApiResponse from './TagApiResponse'
import TagRepository from '@modules/Tag/Domain/TagRepository'

import { News, toNewsDto } from '../TagDto'
import { NewsImageMetadata } from './NewsImageMetadata'

const tagRepository: TagRepository = {
  getSuggestedPepTags: async (term: string): Promise<string[]> => {
    const response = await API.get(`/api/v1/tag?term=${term}`)
    const { data: tags } = response
    const tagsMapped: string[] = Array.from(new Set(tags.map((tag: TagApiResponse) => tag.prettyName)))
    return tagsMapped
  },

  getInfoFromNewsUrl: async (url: string): Promise<News> => {
    const response = await API.get(`/api/v1/news?url=${url}`)
    const { data } = response
    const news = toNewsDto(data)
    return news
  },

  getImageInfo: async (id: string, businessUnit): Promise<NewsImageMetadata> => {
    const response = await API.get(`/api/v1/photo-center/search/${businessUnit}/espana/${id}`)
    const { data } = response
    return data
  }
}

export default tagRepository