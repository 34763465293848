import { IPublicClientApplication } from '@azure/msal-browser'

import AzureService from '@shared/Infrastructure/Services/Azure'

import User from '@modules/User/Domain/User'
import UserApiResponse from './UserApiResponse'
import UserRepository from '@modules/User/Domain/UserRepository'

import { toUserDto } from '@modules/User/Infrastructure/Repository/UserDto'

import { API } from '@shared/Infrastructure/Services/Axios'

const azureService = new AzureService()

const userRepository: UserRepository = {
  login: async (token: string): Promise<User> => {
    const { data: userResponse } = await API.post('/login', {}, { headers: { 'Authorization': `Bearer ${token}` } })
    const userMapped = toUserDto(userResponse as UserApiResponse)
    return userMapped
  },

  logout: async (msalInstance: IPublicClientApplication): Promise<void> => {
    await azureService.azureLogout(msalInstance)
    const token = sessionStorage.getItem('token')
    API.post('/logout', {}, { headers: { 'Authorization': `Bearer ${token}` } })
  }
}

export default userRepository