import { create } from 'zustand'

import EditionState from '@modules/Edition/Presentation/store/EditionState'
import editionRepository from '@modules/Edition/Infrastructure/Repository/EditionRepository'

export const useEditionStore = create<EditionState>((set, get) => {
  return {
    editions: [],
    getEditions: async (businessUnit: string) => {
      const editions = await editionRepository.getEditions(businessUnit)
      const editionsState = get().editions

      if (businessUnit === 'as') {
        set({ editions: [] })
        set({ editions })
      } else {
        set({ editions: [] })
        for (const edition of editions) {
          const editionFound = editionsState.find(editionState => editionState.id === edition.id)
          if (!editionFound) {
            editionsState.push(edition)
          }
        }
  
        set({ editions: editionsState })
      }
    },
    resetEditions: () => {
      set({ editions: [] })
    }
  }
})