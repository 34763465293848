import { Modal as SemanticModal } from 'semantic-ui-react'

type TModal = {
  children: React.ReactNode
  open: boolean
}

const Modal: React.FC<TModal> = ({ open, children }) => {
  return (
    <SemanticModal open={open}>
      { children }
    </SemanticModal>
  )
}

export default Modal