import Edition from '@modules/Edition/Domain/Edition'
import { useTagStore } from '@modules/Tag/Presentation/store/TagStore'

import Container from '@shared/Presentation/components/common/Container'
import Select from '@shared/Presentation/components/common/Select'
import Picture from '@shared/Presentation/components/common/Picture'
import Text from '@shared/Presentation/components/common/Text'

type TEditionCardWithMultipleSelect = {
  edition: Edition
}

const EditionCardWithMultipleSelect: React.FC<TEditionCardWithMultipleSelect> = ({ edition }) => {
  const { tags, removeTag, setTag } = useTagStore()

  const tagsMapped = edition.tags.map(tag => {
    return {
      key: tag.name,
      text: tag.prettyName,
      value: tag.name
    }
  })

  const handleChange = (event, data) => {
    const { value: tagsSelected }: { value: string[] } = data

    const tagsToAdd = tagsSelected.filter(tag => !tags.includes(tag))
    const tagsToRemove = tags.filter(tag => !tagsSelected.includes(tag))

    tagsToAdd.map(tag => setTag(tag))
    tagsToRemove.map(tag => removeTag(tag))
  }

  return (
    <Container>
      <Container className='flex items-center justify-between p-5'>
        <Picture src={`${process.env.PUBLIC_URL}/editions/elpais.webp`} size='tiny' />
        <Text>{edition.prettyName}</Text>
      </Container>
      <Container className='py-2'>
        <Select
          className='w-full'
          multiple={true}
          onChange={handleChange}
          options={tagsMapped}
          placeholder='Tags...'
          value={tags}
        />
      </Container>

    </Container>
  )
}

export default EditionCardWithMultipleSelect