import { useEffect } from 'react'
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react'

import { ToastContainer } from 'react-toastify'

import { useUserStore } from '@modules/User/Presentation/store/UserStore'

import Layout from '@shared/Presentation/components/layout/Layout'
import Router from './Router'

import AzureLoginService from '@shared/Infrastructure/Services/Azure'

const azureService = new AzureLoginService()

function App () {
  const { instance } = useMsal()
  const setUser = useUserStore(state => state.setUser)

  useEffect(() => {
    const login = async () => {
      await azureService.azureLogin(instance)
        .then(user => setUser(user))
    }
    login()
  }, [])

  return (
    <AuthenticatedTemplate>
      <ToastContainer />
      <Layout>
        <Router />
      </Layout>
    </AuthenticatedTemplate>
  )
}

export default App
