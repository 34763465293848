import { useEffect, useState } from 'react'

import { panels as tabPanels } from '@shared/constants/panels'

import { useUserStore } from '@modules/User/Presentation/store/UserStore'

const useGetTabPanels = (panes) => {
  const [ panels, setPanels ] = useState(null)
  const user = useUserStore(state => state.user)

  useEffect(() => {
    if (user) {
      if (user.groups.includes('global')) {
        setPanels(panes)
        return
      }

      const panel = tabPanels.find((tabPanel) => {
        return user.groups.includes(tabPanel.id)
      })
      setPanels([panes[panel.index]])
    }
  }, [user])

  return panels
}

export default useGetTabPanels