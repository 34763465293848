import Swal from 'sweetalert2'

const useShowAlert = () => {
  const showAlert = (
    icon: 'error' | 'info' | 'question' | 'success' | 'warning',
    title: string,
    text: string,
    showCancelButton: boolean, 
    showConfirmButton: boolean, 
    callback: any
  ) => {
    Swal.fire({
      icon,
      title,
      text,
      showCancelButton,
      showConfirmButton,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#16A34A',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        callback()
      }
    })
  }

  return {
    showAlert
  }
}

export default useShowAlert