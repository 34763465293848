import { create } from 'zustand'

import tagRepository from '@modules/Tag/Infrastructure/Repository/TagRepository'
import TagState from '@modules/Tag/Presentation/store/TagState'

export const useTagStore = create<TagState>((set) => {
  const VALID_EXTENSIONS = ['jpg', 'jpeg', 'png']
  const IMAGE_SIZE_REG_EXP = /\.((jpg|png|jpeg))(?=\?|$)/i

  return {
    error: '',
    image: '',
    imageAuth: '',
    imageExt: '',
    imageId: '',
    loading: false,
    suggestedTags: [],
    tags: [],
    getImageFromNewsUrl: async (url: string) => {
      const news = await tagRepository.getInfoFromNewsUrl(url)

      const ext = news.image.match(IMAGE_SIZE_REG_EXP)
      if (!VALID_EXTENSIONS.includes(ext[1]) || null === ext) {
        set({ error: `La plataforma Urban Airship no acepta el formato de la imagen proporcionada por CAPI: .${ext[1]}` })
        return
      }
      set({ image: news.image })
    },
    getSuggestedPepTags: async (term: string) => {
      set({ loading: true })
      const tags = await tagRepository.getSuggestedPepTags(term)
      set({ loading: false, suggestedTags: tags })
    },
    getSuggestedTagsFromNewsUrl: async (url: string) => {
      const news = await tagRepository.getInfoFromNewsUrl(url)
      set({ tags: news.tags })
    },
    getMetadataImage: async (id: string, businessUnit: string) => {
      set({ loading: true })
      const imageData = await tagRepository.getImageInfo(id, businessUnit)
      if (imageData?.cdn.length) {
        const ext = imageData.cdn.match(IMAGE_SIZE_REG_EXP)
        if (!ext) {
          set({ 
            error: 'La extensión de la imagen de PhotoCenter no es soportada por la plataforma de Urban Airship.'
          })
        } else {
          set({
            error: '',
            imageAuth: imageData.auth[0], 
            imageExt: ext[1], 
            imageId: imageData.id
          })
        }
      } else {
        set({ error: 'No se ha podido obtenter la imagen de PhotoCenter.' })
      }
      set({ loading: false })
    },
    setImage: (url: string) => {
      set({ image: url })
    },
    setTag: (tag: string) => {
      set((state) => ({
        tags: [ ...state.tags, tag ],
        suggestedTags: state.suggestedTags.filter(suggestedTag => suggestedTag !== tag)
      }))
    },
    removeTag: (tagToDelete: string) => {
      set((state) => ({
        tags: state.tags.filter(tag => tag !== tagToDelete),
        suggestedTags: [ ...state.suggestedTags, tagToDelete ]
      }))
    },
    resetTags: () => {
      set({ tags: [] })
    },
    resetImage: () => {
      set({ image: '' })
    }
  }
})