type TButton = {
  className?: string
  disabled?: boolean
  children: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit' | 'reset'
}

const Button: React.FC<TButton> = ({ children, className, disabled, onClick, type, ...rest }) => {
  return (
    <button
      className={`rounded-md p-3 ${className} ${disabled && 'opacity-50'} hover:brightness-95`}
      disabled={disabled}
      onClick={onClick}
      type={type}
      {...rest}
    >
      { children }
    </button>
  )
}

export default Button