import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { Message } from 'semantic-ui-react'

import Container from '@shared/Presentation/components/common/Container'

type TErrorMessage = {
  message: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
  type?: 'error' | 'warning'
}

const ErrorMessage: React.FC<TErrorMessage> = ({ message, type = 'error' }) => {
  return (
    <>
      {
        message && <Container className='text-xs p-3'>
          <Message color={type === 'warning' ? 'yellow' : 'red'} header={message} />
        </Container>
      }
    </>

  )
}

export default ErrorMessage