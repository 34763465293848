export const formalizedTag = (tag: string) => {
  if (tag.startsWith('Opted')) {
    return tag
  }

  return `AS_INF_${
    tag
      .replaceAll(' ', '_')
      .replaceAll('Ñ', 'N')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase()}_A_N10`
}