export const asFormFields = {
  image: 'Imagen',
  message: 'Mensaje',
  title: 'Título',
  url: 'URL de la noticia'
}

export const epFormFields = {
  image: 'Imagen',
  message: 'Mensaje',
  title: 'Título',
  url: 'URL de la noticia'
}