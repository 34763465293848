type TText = {
  className?: string
  children: React.ReactNode
}

const Text: React.FC<TText> = ({ children, className }) => {
  return (
    <p className={`${className} m-0`}>
      { children }
    </p>
  )
}

export default Text