import { Button, Icon, Input, Table } from 'semantic-ui-react'

import { useNotificationStore } from '@modules/Notification/Presentation/store/NotificationStore'
import { useDebounce } from '@shared/Presentation/hooks/useDebounce'

type TTableHeader = {
  columns: TColumn[]
}

type TColumn = {
  filter?: boolean
  name: string
  props?: any
  sort?: boolean
  title: string
}

const TableHeader: React.FC<TTableHeader> = ({ columns }) => {
  const { filter, getNotifications, setFilters } = useNotificationStore()

  const columnSorted = () => {
    if (filter.sort !== undefined) {
      const sortSplitted = filter.sort.split('|')
      if (sortSplitted[1] === 'asc') {
        return 'ascending'
      }

      return 'descending'
    }
  }

  const onColumnSort = (name: string) => {
    let order = `${name}|asc`

    if (filter.sort !== undefined) {
      const sortSplitted = filter.sort.split('|')
      if (sortSplitted[1] === 'asc') {
        order = `${name}|desc`
      }
    }

    const filters = {
      ...filter,
      sort: order
    }

    setFilters(filters)
    getNotifications()
  }

  const onSearch = (e, name: string) => {
    const { value } = e.target

    const filters = {
      ...filter,
      filters: {
        ...filter.filters,
        [name]: value
      }
    }

    setFilters(filters)
    onListFilter()
  }

  const onListFilter = useDebounce(() => {
    getNotifications()
  }, 500)

  const onClearFilters = () => {
    const createdByFilter = filter.filters['createdBy']

    setFilters({
      ...filter,
      filters: {
        createdBy: createdByFilter
      }
    })

    onListFilter()
  }

  const onClearFilter = (name: string) => {
    setFilters({
      ...filter,
      filters: {
        ...filter.filters,
        [name]: null
      }
    })

    onListFilter()
  }

  return (
    <Table.Header>
      <Table.Row>
        {
          columns.map(col => (
            <Table.HeaderCell
              key={`col-${col.name}`}
              sorted={columnSorted()}
              onClick={() => col.sort !== false && onColumnSort(col.name)}
            >
              {col.title}
            </Table.HeaderCell>
          ))
        }
        <Table.HeaderCell>Operaciones</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        {
          columns.map(col => (
            <Table.HeaderCell key={`filter-${col.name}`}>
              {
                col.filter !== false 
                && <Input
                  action={{
                    color: 'grey',
                    disabled: !filter.filters[col.name],
                    icon: 'remove',
                    onClick: () => onClearFilter(col.name)
                  }}
                  fluid
                  icon='search'
                  iconPosition='left'
                  placeholder={`Buscar por ${col.title}...`}
                  value={filter.filters[col.name]}
                  onChange={(e) => onSearch(e, col.name)}
                />
              }
            </Table.HeaderCell>
          ))
        }
        <Table.HeaderCell textAlign='center'>
          <Button 
            icon 
            labelPosition='left' 
            title={'Limpiar'} 
            onClick={onClearFilters}
          >
            <Icon name='remove circle' />
            Limpiar
          </Button>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  )
}

export default TableHeader