import { useState } from 'react'

const useModal = () => {
  const [openImageSelectorModal, setOpenImageSelectorModal] = useState<boolean>(false)
  const [openPhotoCenterModal, setOpenPhotoCenterModal] = useState<boolean>(false)
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)
  const [resizerModal, setOpenResizerModal] = useState<boolean>(false)

  const openResizerModal = () => setOpenResizerModal(true)

  const closePhotoCenterModal = () => setOpenPhotoCenterModal(false)
  const closeResizerModal = () => setOpenResizerModal(false)

  const toggleImageSelectorModal = () => setOpenImageSelectorModal((state: boolean) => !state)
  const togglePhotoCenterModal = () => setOpenPhotoCenterModal((state: boolean) => !state)
  const togglePreviewModal = () => setOpenPreviewModal((state: boolean) => !state)
  const toggleResizeModal = () => setOpenResizerModal((state: boolean) => !state)

  return {
    openImageSelectorModal,
    openPhotoCenterModal,
    openPreviewModal,
    resizerModal,
    closePhotoCenterModal,
    closeResizerModal,
    openResizerModal,
    toggleImageSelectorModal,
    togglePhotoCenterModal,
    togglePreviewModal,
    toggleResizeModal
  }
}

export default useModal