type TSendIcon = {
  className?: string
}

const SendIcon: React.FC<TSendIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="24" 
      stroke="white" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 14l11 -11" />
      <path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5" />
    </svg>
  )
}

export default SendIcon