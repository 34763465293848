import { useEffect } from 'react'

import { useTagStore } from '@modules/Tag/Presentation/store/TagStore'

import Modal from '@shared/Presentation/components/common/modal/Modal'

type TPhotoCenterModal = {
  businessUnit: string
  isShowing: boolean
  closePhotoCenterModal: () => void
  openResizerModal: () => void
}

const PhotoCenterModal: React.FC<TPhotoCenterModal> = ({ businessUnit, isShowing, closePhotoCenterModal, openResizerModal }) => {
  const arcUrl = 'https://widgetarc.prisasd.com'
  const endpoint = `https://widgetarc.prisasd.com/photocenter/listpublic/images?accountName=${businessUnit}`

  const { getMetadataImage } = useTagStore()

  useEffect(() => {
    const handler = messageDispatch.bind(this)
    window.addEventListener('message', handler, false)

    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  const messageDispatch = (event: MessageEvent) => {
    if (event.origin !== arcUrl) {
      return
    }

    if (typeof event.data === 'string') {
      const data = JSON.parse(event.data)

      if (data.source === 'custom_embed' && data.action === 'cancel') {
        closePhotoCenterModal()
        return
      }
  
      if (data.id && data.url) {
        getMetadataImage(data.id, businessUnit)
        closePhotoCenterModal()
        openResizerModal()
      }
    }
  }

  return (
    <>
      <Modal open={isShowing}>
        <div style={{ display: 'block', paddingRight: '15px' }}>
          <div style={{ height: '90vh', maxHeight: 'calc(100% - 3.5rem)', maxWidth: '1140px' }}>
            <div style={{ height: '100%' }}>
              <iframe
                src={endpoint}
                title='Photo Center'
                style={{ height: '90vh', width: '1140px' }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PhotoCenterModal