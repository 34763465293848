import { useNotificationStore } from '@modules/Notification/Presentation/store/NotificationStore'

import useNotificationTableInfo from '@modules/Notification/Presentation/hooks/useNotificationTableInfo'

import FormLoader from '@shared/Presentation/components/common/Loader'
import Table from '@shared/Presentation/components/common/table/Table'

const NotificationTable = () => {
  const { columns, data } = useNotificationTableInfo()
  const { isLoading, filter, totalPages } = useNotificationStore()

  return (
    <>
      {
        isLoading 
          ? <FormLoader text='Cargando notificaciones...' />
          : <Table
            color='red' 
            columns={columns} 
            data={data} 
            isLoading={isLoading}
            pagination={filter}
            totalPages={totalPages}
          />
      }
    </>
  )
}

export default NotificationTable