const NotificationIcon = () => {
  return (
    <svg 
      fill="none" 
      height="36" 
      stroke="currentColor" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      strokeWidth="2" 
      viewBox="0 0 24 24"
      width="36"
      xmlns="http://www.w3.org/2000/svg" 
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
    </svg>
  )
}

export default NotificationIcon