export const editionsTags = {
  'AS_INF_GEN_ALL_ALL_NOT': 'AS España',
  'AS_INF_GEN_ALL_ALL_NOT_ES_CO': 'AS Colombia',
  'AS_INF_GEN_ALL_ALL_NOT_ES_CL': 'AS Chile',
  'AS_INF_GEN_ALL_ALL_NOT_ES_MX': 'AS México',
  'AS_INF_GEN_ALL_ALL_NOT_ES_US': 'AS US',
  'AS_INF_GEN_ALL_ALL_NOT_EN': 'AS English',
  'AS_INF_GEN_ALL_ALL_NOT_ES_AR': 'AS Argentina',
  'Opted_In_Podcasts': 'Podcasts España',
  'Opted_In_Business': 'Economía España',
  'Opted_In_Sports_2023': 'Deportes España',
  'Opted_In_Featured': 'Contenido Destacado',
  'Opted_In_Alerts': 'Actualidad España',
  'Opted_In_Sports_2023_America': 'Deportes América',
  'Opted_In_Alerts_America': 'Actualidad América',
  'Opted_In_Sports_2023_Mexico': 'Deportes México',
  'Opted_In_Alerts_Mexico': 'Actualidad México',
  'Opted_In_Sports_2023_Colombia': 'Deportes Colombia',
  'Opted_In_Alerts_Colombia': 'Actualidad Colombia',
  'Opted_In_Alerts_Chile': 'Actualidad Chile',
  'Opted_In_Sports_2023_Chile': 'Deportes Chile'
}

export const LOADING_TAGS_SEARCH = 'Buscando...'
export const NOT_ENOUGH_CHARS_TAGS_SEARCH = 'Introduce al menos 3 caracteres'
export const NO_TAGS_FOUND = 'No se han encontrado tags'