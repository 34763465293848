import User from '@modules/User/Domain/User'
import userRepository from '@modules/User/Infrastructure/Repository/UserRepository'

class AzureLogin {
  user: User = null

  async azureLogin (msalInstance) {
    await msalInstance.handleRedirectPromise()
      .then(async response => {
        if (response) {
          const { accessToken } = response
          sessionStorage.setItem('token', accessToken)
          const user = await userRepository.login(accessToken)
          this.user = user
        } else {
          await this.azureRefreshToken(msalInstance)
        }
      })

    return this.user
  }

  async azureRefreshToken (msalInstance) {
    const account = msalInstance.getAllAccounts()[0]
    if (!account) {
      msalInstance.loginRedirect()
      return
    }

    const accessTokenRequest = {
      scopes: ['user.read'],
      account
    }

    await msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then(async response => {
        const { accessToken } = response
        sessionStorage.setItem('token', accessToken)
        const user = await userRepository.login(accessToken)
        this.user = user
      })
      .catch(error => {
        if (error) msalInstance.acquireTokenRedirect(accessTokenRequest)
      })
  }

  async azureLogout (msalInstance) {
    await msalInstance.logoutRedirect()
  }
}

export default AzureLogin
