import { DropdownProps, Grid, Icon, Pagination, PaginationProps, Table } from 'semantic-ui-react'

import { useNotificationStore } from '@modules/Notification/Presentation/store/NotificationStore'

import Select from '@shared/Presentation/components/common/Select'

type TTableFooter = {
  pagination: TPagination
  totalColumns: number
  totalPages: number
}

type TPagination = {
  page: number
  limit: number
}

const TableFooter: React.FC<TTableFooter> = ({ pagination, totalColumns, totalPages }) => {
  const { filter, getNotifications, setFilters } = useNotificationStore()

  const limitOptions = [
    { key: '5', value: 5, text: '5' },
    { key: '10', value: 10, text: '10' },
    { key: '15', value: 15, text: '15' },
    { key: '20', value: 20, text: '20' },
    { key: '25', value: 25, text: '25' },
    { key: '50', value: 50, text: '50' }
  ]

  const onLimitChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data
  
    const filters = {
      ...filter,
      limit: Number(value),
      page: 1
    }

    setFilters(filters)
    getNotifications()
  }

  const onPageChange = (_: React.SyntheticEvent<HTMLElement>, data: PaginationProps) => {
    const { activePage } = data

    const filters = {
      ...filter,
      limit: pagination.limit, 
      page: Number(activePage)
    }

    setFilters(filters)
    getNotifications()
  }

  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={totalColumns}>
          <Grid columns={2} verticalAlign='middle'>
            <Grid.Column>
              <div className='gap-1 items-center flex'>
                Página { pagination.page } de { totalPages || 0 },
                mostrar
                <Select
                  options={limitOptions}
                  value={filter.limit}
                  onChange={onLimitChange}
                />
                elementos
              </div>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Pagination 
                activePage={pagination.page}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                totalPages={totalPages || 0}
                onPageChange={onPageChange}
              />
            </Grid.Column>
          </Grid>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  )
}

export default TableFooter