import { useMsal } from '@azure/msal-react'

import Button from '@shared/Presentation/components/common/Button'
import Container from '@shared/Presentation/components/common/Container'
import LogoutIcon from '@shared/Presentation/icons/LogoutIcon'
import Navbar from '@shared/Presentation/components/layout/Navbar'
import Text from '@shared/Presentation/components/common/Text'

import userRepository from '@modules/User/Infrastructure/Repository/UserRepository'
import { useUserStore } from '@modules/User/Presentation/store/UserStore'

const Header = () => {
  const { instance } = useMsal()
  const user = useUserStore(state => state.user)

  return (
    <header className='bg-slate-400 flex justify-between p-3'>
      <Container className='flex gap-5 items-center'>
        <Text className='text-xl'>NOTIFICACIONES</Text>
        <Navbar />
      </Container>
      <section className='flex gap-2'>
        <Container>
          <Text className='m-0 text-lg'>{user?.name}</Text>
          <Text>{user?.email}</Text>
        </Container>
        <Button
          onClick={() => userRepository.logout(instance)}
        >
          <LogoutIcon />
        </Button>
      </section>
    </header>
  )
}

export default Header