import { Dropdown } from 'semantic-ui-react'

type TOption = {
  key: string
  text: string
  value: string | number
}

type TSelect = {
  className?: string
  defaultValue?: any
  multiple?: boolean
  onChange: (event: any, data: any) => void
  options: TOption[]
  placeholder?: string
  value?: string | number | string[] | number[]
}

const Select: React.FC<TSelect> = ({ className, defaultValue, multiple, onChange, options, placeholder, value }) => {
  return (
    <Dropdown
      className={className}
      defaultValue={defaultValue}
      multiple={multiple}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      selection
      value={value}
    />
  )
}

export default Select