import { Loader } from 'semantic-ui-react'

import Container from '@shared/Presentation/components/common/Container'

type TLoader = {
  text?: string
}

const FormLoader: React.FC<TLoader> = ({ text = 'Cargando...' }) => {
  return (
    <Container className='flex justify-center'>
      <Loader 
        active 
        inline
        size='big'
      >
        { text }
      </Loader>
    </Container>
  )
}

export default FormLoader