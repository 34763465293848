import { Checkbox } from 'semantic-ui-react'

type TEditionCheckbox = {
  checked: boolean
  onClick: any
}

const EditionCheckbox: React.FC<TEditionCheckbox> = ({ checked, onClick }) => {
  return (
    <Checkbox
      checked={checked}
      onClick={onClick}
    />
  )
}

export default EditionCheckbox