import Swal from 'sweetalert2'

import notificationRepository from '@modules/Notification/Infrastructure/Repository/NotificationRepository'

import Button from '@shared/Presentation/components/common/Button'

type TResendNotificationButton = {
  id: string
}

const ResendNotificationButton: React.FC<TResendNotificationButton> = ({ id }) => {
  
  const handleClick = () => {
    Swal.fire({
      icon: 'question',
      title: 'Reenviar notificación',
      text: '¿Está seguro de que desea reenviar la notificación?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#16A34A',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        notificationRepository.resend(id)
      }
    })
  }

  return (
    <Button className='bg-slate-500 p-2 text-white' onClick={handleClick}>
      Reenviar
    </Button>
  )
}

export default ResendNotificationButton