import { API } from '@shared/Infrastructure/Services/Axios'

import NotificationApiResponse from '@modules/Notification/Application/Response/NotificationApiResponse'

import toaster from '@shared/Infrastructure/Toaster/Toaster'

import { TQueryFilters } from '@modules/Notification/types'

export const listNotifications = async (filters: TQueryFilters) => {
  try {
    const apiResponse = await API.get('/api/v1/notification', { params: filters })
    const { data }: { data: NotificationApiResponse } = apiResponse
    const { items, page, rowsPerPage, totalInCollection } = data
    const response = { items, page, rowsPerPage, totalInCollection }
    return response
  } catch (error) {
    toaster.error('No se han podido obtener las notificaciones')
  }
}