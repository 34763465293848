import { NewsApiResponse } from './Repository/NewsApiResponse'
import TagApiResponse from './Repository/TagApiResponse'

export interface News {
  image: string
  tags: string[]
}

export const toNewsDto = (data: NewsApiResponse): News => {
  return {
    image: data.image,
    tags: Array.from(new Set(data.tags.map((tag: TagApiResponse) => tag.prettyName)))
  }
}