import { useState } from 'react'
import { useLocation } from 'wouter'
import { Menu } from 'semantic-ui-react'

import Container from '@shared/Presentation/components/common/Container'

const Navbar = () => {
  const ROUTES = {
    send: '/',
    list: '/notifications/list'
  }

  const [activeItem, setActiveItem] = useState('ENVIAR')
  const [, setLocation] = useLocation()

  const handleClick = (e, { name }) => {
    setActiveItem(name)
    if (name === 'ENVIAR') {
      setLocation(ROUTES.send)
    } else {
      setLocation(ROUTES.list)
    }
  }

  return (
    <Container>
      <Menu secondary>
        <Menu.Item
          active={activeItem === 'ENVIAR'}
          disabled={activeItem === 'ENVIAR'}
          name='ENVIAR'
          onClick={handleClick}
        />
        <Menu.Item
          active={activeItem === 'CONSULTAR MIS NOTIFICACIONES'}
          disabled={activeItem === 'CONSULTAR MIS NOTIFICACIONES'}
          name='CONSULTAR MIS NOTIFICACIONES'
          onClick={handleClick}
        />
      </Menu>
    </Container>
  )
}

export default Navbar