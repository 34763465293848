import Edition from '@modules/Edition/Domain/Edition'
import EditionApiResponse from '@modules/Edition/Infrastructure/Repository/EditionApiResponse'

export const toEditionDto = (editions: EditionApiResponse[]): Edition[] => {
  const editionsMapped: Edition[] = editions.map(edition => {
    return {
      id: edition.id,
      prettyName: edition.prettyName,
      city: edition.city,
      locale: edition.locale,
      timezone: edition.timeZone,
      tags: edition.tags,
      enable: edition.enable
    }
  })

  return editionsMapped
}